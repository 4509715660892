import React from 'react';
import { motion } from 'framer-motion';
import { images } from '../../constants';

import { AppWrap } from '../../wrapper'
import './Header.scss';



const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  return (
    <div id='accueil' className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
           
            <div style={{ marginLeft: 10 }}>
              <div className="p-text"></div>
              <h1>Cabinet Hayani Coaching et Formation</h1>
            </div>
          </div>
          <div className="tag-cmp app__flex">
          <p className="p-text">Fais de ta vie un rêve, et d&#39;un rêve, une réalité »</p>
          <p className="p-text">"Antoine de Saint-Exupéry</p>
        </div>
        </div>
      </motion.div>
      <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img"
    >
      <img src={images.profile} alt="profile_bg" />
      <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        src={images.circle}
        alt="profile_circle"
        className="overlay_circle"
      />
    </motion.div>
    <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
    >
      {[images.ab1, images.ab2, images.ab3].map((circle, index) => (
        <motion.div 
        
            whileHover={{ scale: 1.1 }}
            
         className="circle-cmp app__flex" key={`circle-${index}`}>
         <a className="circle-cmp app__flex" href='#projet'><img src={circle} alt="profile_bg" /></a> 
        </motion.div>
      ))}
    </motion.div>
    </div>
  );
};

export default AppWrap( Header, 'accueil') ;
