import { createClient } from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
  projectId: 'sgmqi4s7',
  dataset: 'production',
  apiVersion: '2023-10-10',
  useCdn: true,
  token: 'skVWKMKiVXCQonyZqwymXutPzmG00Hb84wMJM2D3m5JU8NjByE1FFFiIkIFKfPuTFJ5sXuvAnUsgtghPthhxedl3ZxrcNXZRrhPPXS8cOzTA2QBSyqvXV5WmcJ1mFoHT3FPeOZ5SUNwGgoF7dwtUlXX40XOXoS0CfV99d7n73rAtdHH5edBo',
});
const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);