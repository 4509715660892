import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import bgIMG from '../assets/bgIMG.png';
import bgWhite from '../assets/bgWhite.png';
import formation from '../assets/formation.png';
import ab1 from '../assets/ab1.png';
import ab2 from '../assets/ab2.png';
import ab3 from '../assets/ab3.png';
import accompagner from '../assets/accompagner.png';
import loca from '../assets/loca.png'



import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';



export default {
  loca,
  accompagner,
  formation,
  bgWhite,
  bgIMG,
  email,
  mobile,
  profile,
  circle,
  logo,
  ab1,
  ab2,
  ab3

};