import React from 'react';
import { BsWhatsapp,BsInstagram, BsFacebook } from 'react-icons/bs';

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
      <a href='https://wa.me/2120620086996'><BsWhatsapp /></a>
      </div>
      <div>
      <a href='https://instagram.com/cabinet.hayani?igshid=MzMyNGUyNmU2YQ=='><BsInstagram /></a>
      </div>
      <div>
      <a href='https://www.facebook.com/profile.php?id=100063594276348&mibextid=ZbWKwL'><BsFacebook /></a>
      </div>
    
    </div>
  );
};

export default SocialMedia;
